body{
  margin:0px;
  padding: 0px;
  background-color: #f4f4f5;
  font-family: Arial, Helvetica, sans-serif;
}

.App {
  text-align: center;
}

.AppLoader{
  width:150px;
  height: auto;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

canvas.drawing, canvas.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
}

.overlay__content video {
  width: 100%;
  height: 100%;
}

.overlay__content canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

#interactive.viewport {
  position: relative;
}

#interactive.viewport > canvas, #interactive.viewport > video {
  max-width: 100%;
  width: 100%;
}

.overlay__content {
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-height: 90%;
  max-width: 800px;
}

.marginBottom{
  margin-bottom: 10px !important;
}

#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #58585a;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
}
#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

#snackbarVersion {
  visibility: hidden;
  max-width: 350px;
  margin-left: -125px;
  background-color: #58585a;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: relative;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  margin-top: calc(100vh - 50vh);
  bottom: 30px;
}
#snackbarVersion.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}


.redCheck{
  color: #DD0060 !important;
}

.greenCheck{
  color: #3db498 !important;
}

.fadeOut{
  opacity:0;
  transition: opacity 1s;
}
.fadeIn{
  transition: all;
  transform: scale(1);
  opacity: 1;
}
.fadeIn{
  animation-duration: .3s;
  animation-name: fadeAndScale;
  animation-timing-function: cubic-bezier(.71,.55,.62,1.57);
}
.displayNone{
  opacity:0;
}
@keyframes fadeAndScale {
  from {
      opacity: 0;
      duration: 500; 
      timing: "ease"; 
      fill: "both";
      transform: scale(.9, .9);
  }
  to {
    opacity: 1;
    duration: 500; 
    timing: "ease"; 
    fill: "both";
    transform: scale(1, 1);
  }
}

/* POD VELIKIM ZNAKOM PITANJA */
.sc-AxirZ{
  overflow-y:scroll !important;
}
/* POD VELIKIM ZNAKOM PITANJA */

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #dd0060;
  color: #dd0060;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #dd0060;
  color: #dd0060;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #dd0060;
  color: #dd0060;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #dd0060;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}

.snippet {
  width: '150px !important';
  position: relative;
  padding: 0rem 5%;
  margin: 1.5rem 0;
  border-radius: .25rem;
}

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0rem 0;
  margin: 0 -5%;
  overflow: hidden;
}